<template lang="pug">  
  .kr-split-payment(:class="[hover ? 'kr-hover' : '']")
    span.kr-split-payment-check(v-html="checkIcon")
    BrandIcon(:method="transaction.brand")
    SmartFormMaskedCardLabel(:value="formatCardNumber(transaction.pan, transaction.brand)")
    span.kr-split-payment-amount {{getAmountLabel(transaction.amount)}}
    .kr-split-payment-remove-icon-container(        
      @mouseover="hover = true"
      @mouseleave="hover = false"
    )
      button.kr-split-payment-remove-icon(type="button", @click="cancelSplitPayment()", v-html="removeIcon")
</template>

<script>
import { mapGetters } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import BrandIcon from '@/host/components/controls/BrandIcon'
import SmartFormMaskedCardLabel from '@/host/components/smartform/MaskedCardLabel'
import { formatCardNumber } from '@/common/service/CardUtils'

export default {
  name: 'SplitPaymentEntry',
  components: { BrandIcon, SmartFormMaskedCardLabel },
  props: {
    transaction: { type: Object, required: true }
  },
  data() {
    return {
      hover: false,
      removeIcon: PreloadedAssets.remove.remove_icon,
      checkIcon: PreloadedAssets.check
    }
  },
  computed: {
    ...mapGetters(['getAmountLabel'])
  },
  methods: {
    cancelSplitPayment() {
      this.$proxy.send(
        this.$storeFactory.create('declinePartialPaymentCall', {
          transactionUuid: this.transaction.uuid
        })
      )
    },
    formatCardNumber(card, brand) {
      return formatCardNumber(card, brand)
    }
  }
}
</script>
