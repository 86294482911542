export const getLayoutState = () => {
  return {
    layout: 'default', // compact | grid
    otherPaymentMethods: {
      labelVisibility: 'show', // "show" | "hide" | "auto"
      grid: {
        columns: 'max', // <integer> | "max"
        justify: 'start',
        button: {
          minWidth: 50,
          paddingRight: 0
        }
      },
      navigation: {
        views: ['main', 'group'], // main, group
        currentView: 'main',
        history: []
      }
    }
  }
}

export const layoutActions = ($locator, app) => {
  return {
    opmNavigate({ commit, state }, view) {
      if (!state.smartForm.otherPaymentMethods.navigation.views.includes(view))
        return
      commit('UPDATE', {
        smartForm: {
          otherPaymentMethods: {
            navigation: {
              currentView: view
            }
          }
        }
      })
    }
  }
}
