import GridConfigMixin from '@/host/components/mixins/grid/Config'
import ColumnsMixin from '@/host/components/mixins/grid/Columns'

/**
 * Mixin for grid group computation
 */
export default {
  mixins: [GridConfigMixin, ColumnsMixin],
  data() {
    return {
      width: 0,
      gap: 0,
      /**
       * Visible number of columns, can be different than effectiveColumns
       * with justify: stretch.
       * Required for button's grid-column-start computation.
       *
       * @since KJS-4196
       */
      visibleColumns: 1,
      /**
       * Value tombe injected in repeat CSS function
       *
       * @since KJS-4113
       */
      effectiveColumns: 1
    }
  },
  computed: {
    groupMethods() {
      return this.filteredMethods.length
    },
    gridStyles() {
      return {
        gridTemplateColumns: `repeat(${this.effectiveColumns}, 1fr)`
      }
    },
    showLabels() {
      // Only main view label visibility can be forced
      if (this.type === 'main' && this.labelVisibility !== 'auto') {
        return this.labelVisibility === 'show'
      }

      // By default, auto adjust
      // If all labels can be displayed then return true
      return this.allLabelFit
    }
  },
  watch: {
    labelVisibility: 'onGridChange',
    columns: 'onGridChange',
    justify: 'onGridChange',
    paymentMethodGroups: {
      handler() {
        this.onGridChange()
      },
      deep: true
    },
    width: 'onGridChange',
    gap: 'onGridChange'
  },
  mounted() {
    // Force recompute on mounted
    this.onResize()
  },
  methods: {
    /**
     * Resize handler should only update variables. This allows, during
     * transitions, to ignore intermediate values (e.g: 305.32px) and trigger
     * calculation only when the integer value has changed.
     *
     * @since KJS-4113
     */
    onResize() {
      const { width, gap } = this.getGridElementSizes()
      this.width = width
      this.gap = gap
    },
    /**
     * Calculate the effective number of columns to render.
     *
     * @since KJS-4113
     */
    async onGridChange() {
      this.effectiveColumns = await this.getGridColumns()
    },
    async getGridColumns() {
      if (!this.width) return 1 // Not mounted yet - default to 1

      const columns = await this.computeColumns(this.width, this.gap)
      this.visibleColumns = columns

      if (this.justify === 'start') return columns
      // justify stretch
      const remainder = this.groupMethods % columns
      const fitsOnGrid = !(columns % remainder)
      if (fitsOnGrid) return columns
      return columns * remainder
    },
    getGridElementSizes() {
      return {
        width: this.$el?.clientWidth ?? 0,
        gap: getComputedStyle(this.$el)?.gap.replace('px', '') || 0
      }
    }
  }
}
