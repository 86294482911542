<template lang="pug">
  .kr-smart-form-methods
    SmartFormEmbeddedHeader(v-if="renderGenericEmbeddedHeader")
    SplitPaymentPanel(v-if="!cardsFormExpanded")
    SmartFormMethodsListLabel(
      :mode="isGroupingActivated ? 'groups' : 'methods'"
      :all-locked="paymentDone || hasAnyActiveMethod"
      :is-modal="false"
      v-on:group-selected="loadGroup"
      v-on:selected="setMethod"
      v-on:back="goBack"
    )
      template(v-slot:cardForm="")
        slot(name="cardForm")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormEmbeddedHeader from '@/host/components/smartform/EmbeddedHeader'
import SmartFormMethodsListLabel from '@/host/components/smartform/MethodsListLabel'
import SmartFormMethodsListContent from '@/host/components/smartform/MethodsListContent'
import SplitPaymentPanel from '@/host/components/smartform/SplitPaymentPanel'

import { SmartFormMethodsList } from '@/host/components/mixins/SmartFormMethodsList'

export default {
  name: 'SmartFormEmbeddedMethodsList',
  components: {
    SmartFormEmbeddedHeader,
    SmartFormMethodsListLabel,
    SmartFormMethodsListContent,
    SplitPaymentPanel
  },
  mixins: [SmartFormMethodsList],
  computed: {
    ...mapState(['amount']),
    ...mapState({
      paymentDone: state => state.smartForm.paymentDone
    }),
    ...mapGetters([
      'hasAnyActiveMethod',
      'isGroupingActivated',
      'renderGenericEmbeddedHeader',
      'cardsFormExpanded'
    ])
  }
}
</script>
