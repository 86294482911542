import { isEmpty } from 'underscore'
import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import payment from '@/common/util/payment'
import Events from '@/configuration/Events'
import { calculateCallbackUrl } from '../../workflow/payment/state/DataFormatState'

/**
 * This class handles the reception of the appleOrGooglePayPayment store action
 * from the host and calls the processPayment and executes the workflow
 */
export default class AppleOrGooglePayPaymentTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'appleOrGooglePayPayment')
    this.browserRequests = $locator.browserRequests
    this.setupListeners()
  }

  setupListeners() {
    /*
     * For apple pay with extra fields, need to save the payment token data to be
     * used later after the extras form validation
     */
    this.$bus.$on(
      Events.ghost.appleOrGooglePay.paymentToken,
      ({ paymentToken }) => {
        this.paymentToken = paymentToken
      }
    )
  }

  /**
   * Apple Or Google Payment
   *
   * @method onEvent
   * @param {Apple_Pay_Payment_Action} appleOrGooglePayPayment
   */
  async onEvent({ paymentToken, payloadType }) {
    try {
      // For apple pay with extra fields
      if (isEmpty(paymentToken) && this.paymentToken)
        paymentToken = this.paymentToken

      await super.onEvent({ paymentToken })

      if (this.$store.getters.extrasFormHasData) {
        // Required to properly reset form $ button after error
        this.$store.dispatch('paymentStart')

        // Validate extra fields form
        this.extraFieldValidation()
      }

      // get the payment token for the simulator
      if (
        this.$store.getters.isApplePaySimulator &&
        payloadType === 'APPLE_PAY'
      )
        paymentToken = this.$store.getters.getApplePayTestToken

      // payment handled by payment trigger for 3ds
      if (payloadType === 'GOOGLEPAY') {
        const { objectData } = this.buildProcessPaymentPayload(
          payloadType,
          paymentToken
        )
        if (this.formTokenConsumed) return
        const { riskAnalyser } = this.$store.state
        // If fingerPrintId is defined we add it
        if (riskAnalyser?.fingerPrintsId) {
          objectData.fingerPrintId = riskAnalyser.fingerPrintsId
          this.$locator.logger.log(
            'info',
            'ProcessPayment request with fingerPrintId'
          )
        }
        objectData.kryptonCallbackUrl = calculateCallbackUrl()
        this.$store.dispatch('paymentStart')
        const extra = {}
        // Pay action
        this.$bus.$emit(Events.krypton.message.pay, {
          formId: undefined,
          formToken: objectData.formToken,
          extra,
          googlePayData: {
            formObj: {
              url: objectData,
              endpoint: this.endpoint
            }
          }
        })
        return
      }

      // Process the payment
      const { response } = await this.processApplePayPayment(
        paymentToken,
        payloadType
      )
      // Validate the response
      if (this.isValid(response)) {
        // Transaction - send the data to the payment handler
        payment.handler(response.answer, this.$locator)
      } else {
        this.onError({
          metadata: { answer: response.answer },
          paymentMethod: payloadType
        })
      }
    } catch (error) {
      this.onError(error, payloadType)
    }
  }

  onError(
    error,
    payloadType,
    path = 'ghost/service/AppleOrGooglePayPaymentTrigger.onEvent'
  ) {
    if (payloadType) error.paymentMethod = payloadType
    super.onError(error, path)

    if (!this.$store.getters.isExtrasFormVisible)
      this.$store.dispatch('closeMethod')

    if (error.paymentMethod === 'APPLE_PAY')
      this.proxy.send(this.storeFactory.create('applePayPaymentError'))
  }

  /**
   * Calls the ProcessPayment
   *
   * @param {Object} paymentToken
   * @returns {Promise}
   */
  async processApplePayPayment(token, payloadType) {
    const { objectData, url } = this.buildProcessPaymentPayload(
      payloadType,
      token
    )

    const requestData = this.storeFactory.create('requestData', {
      url,
      objectData,
      headers: {},
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }

  buildProcessPaymentPayload(payloadType, token) {
    const { formToken, publicKey, remoteId } = this.$store.state
    const tokenData =
      payloadType === 'APPLE_PAY'
        ? {
            token: {
              paymentData: token.paymentData,
              paymentMethod: token.paymentMethod,
              transactionIdentifier: token.transactionIdentifier
            }
          }
        : token
    const objectData = {
      formToken,
      publicKey,
      paymentForm: {
        walletPayload: JSON.stringify(tokenData),
        brand: payloadType,
        ...this.getFormAdditionalData()
      },
      remoteId,
      clientVersion: this.restAPI.getClientVersion(),
      device: this.restAPI.getDeviceData(),
      wsUser: this.restAPI.getWSUser()
    }
    const url = this.restAPI.addJSessionID(
      this.restAPI.getProcessPaymentUrl(this.endpoint)
    )
    return { objectData, url }
  }

  /**
   * Validates the server response object
   *
   * @param {*} response
   * @returns {boolean}
   */
  isValid(response) {
    return !(
      response.status === 'ERROR' ||
      response.answer?.errorCode ||
      response.answer.clientAnswer?.transactions[0].errorCode
    )
  }

  /**
   * Returns the additional data for the form
   * @returns {Object}
   */
  getFormAdditionalData() {
    const { state } = this.$store
    const nonSensitiveValues = {}
    const formId = state.forms[state.activeForm]
    state.extrasForm.fields.forEach(fieldName => {
      nonSensitiveValues[fieldName] =
        state[`cardForm_${formId}`].nonSensitiveValues[fieldName]
    })

    if (nonSensitiveValues.installmentNumber == '-1')
      delete nonSensitiveValues.installmentNumber

    return nonSensitiveValues
  }

  extraFieldValidation() {
    const { state } = this.$store
    const formId = state.forms[state.activeForm]

    // Add minimal required info for field validation
    // Don't need any more as we will never validate a sensitive data field from here
    const fields = {}

    Object.keys(state.extrasForm.dna).forEach(fieldName => {
      const strategy = this.$locator.strategyDetector.get(fieldName, formId)
      const validate = () => strategy.validate()
      fields[fieldName] = { validate, formId }
    })

    const errors = this.$locator.validationManager.validate(formId, fields)
    if (errors) throw errors
  }
}
