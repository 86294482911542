<template lang="pug">  
  .kr-split-payment-panel(v-if="hasSplitPayments")
    .kr-split-payment-label {{translate("split_payment_label")}}
    TransitionGroup(name="test" tag="div" class="kr-split-payments") 
      SplitPaymentEntry(
        v-for="transaction, i in transactions" 
        :key="i"
        :transaction="transaction"
      )
    .kr-split-payment-remaining
      span.kr-split-payment-remaining-label {{translate("split_payment_left")}}
      span.kr-split-payment-remaining-amount {{getAmountLabel(pendingAmount)}}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SplitPaymentEntry from '@/host/components/smartform/SplitPaymentEntry'

export default {
  name: 'SplitPaymentPanel',
  components: { SplitPaymentEntry },
  computed: {
    ...mapGetters(['getAmountLabel', 'translate', 'hasSplitPayments']),
    ...mapState({
      transactions: state => state.splitPayment.transactions,
      pendingAmount: state => state.amount,
      initialAmount: state => state.initialAmount
    })
  }
}
</script>
