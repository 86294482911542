var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-split-payment", class: [_vm.hover ? "kr-hover" : ""] },
    [
      _c("span", {
        staticClass: "kr-split-payment-check",
        domProps: { innerHTML: _vm._s(_vm.checkIcon) },
      }),
      _c("BrandIcon", { attrs: { method: _vm.transaction.brand } }),
      _c("SmartFormMaskedCardLabel", {
        attrs: {
          value: _vm.formatCardNumber(
            _vm.transaction.pan,
            _vm.transaction.brand
          ),
        },
      }),
      _c("span", { staticClass: "kr-split-payment-amount" }, [
        _vm._v(_vm._s(_vm.getAmountLabel(_vm.transaction.amount))),
      ]),
      _c(
        "div",
        {
          staticClass: "kr-split-payment-remove-icon-container",
          on: {
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
          },
        },
        [
          _c("button", {
            staticClass: "kr-split-payment-remove-icon",
            attrs: { type: "button" },
            domProps: { innerHTML: _vm._s(_vm.removeIcon) },
            on: {
              click: function ($event) {
                return _vm.cancelSplitPayment()
              },
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }