var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (!_vm.isSyncReady && _vm.isVisible) ||
    !_vm.hasValidToken ||
    _vm.forceSkeleton
    ? _c("SmartButtonSkeleton", { attrs: { "is-fallback": _vm.isFallback } })
    : _vm.isSyncReady && _vm.isVisible
    ? _c(
        "div",
        {
          staticClass: "kr-smart-button kr-sm-internal",
          class: _vm.dynamicClasses,
          style: _vm.styles.button,
          attrs: {
            title: _vm.title,
            "kr-payment-method": _vm.cleanPaymentMethod.toLowerCase(),
            "kr-brand": _vm.brands.toLowerCase(),
            role: "button",
            tabindex: "0",
          },
          on: {
            click: function ($event) {
              return _vm.triggerMethod(_vm.paymentMethod, $event)
            },
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
          },
        },
        [
          _vm.showRadioButton
            ? _c("SmartFormRadioButton", {
                ref: "radioButton",
                attrs: { checked: _vm.isSelected, hover: _vm.hover },
              })
            : _vm._e(),
          _vm.hasRegularLabel
            ? _c(
                "div",
                { staticClass: "kr-method-info" },
                [
                  _vm.icon
                    ? _c("div", {
                        staticClass: "kr-method-icon",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getCustomPaymentMethodIcon(
                              _vm.cleanPaymentMethod
                            ) || _vm.icon
                          ),
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "kr-label" }, [
                    _c("label", { staticClass: "kr-method-label" }, [
                      _vm._v(_vm._s(_vm.regularMethodLabel)),
                    ]),
                    _vm.showAmount
                      ? _c("label", { staticClass: "kr-amount-label" }, [
                          _vm._v(_vm._s(_vm.amountLabel)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("SmartFormMethodBadge", {
                    attrs: { method: _vm.cleanPaymentMethod },
                  }),
                ],
                1
              )
            : !_vm.showLoadingAnimation
            ? _c(
                "div",
                { staticClass: "kr-method-info" },
                [
                  _c("SmartFormCardIcons", {
                    attrs: {
                      brands: _vm.displayedBrands,
                      "brand-filter": _vm.brandFilter,
                      margin: 5,
                      "is-smart-button": true,
                    },
                    on: { "num-of-brands": _vm.setNumOfBrands },
                  }),
                  _vm.hasSingleBrand
                    ? _c("div", { staticClass: "kr-label" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "kr-method-label kr-single-brand-label",
                          },
                          [_vm._v(_vm._s(_vm.methodLabel))]
                        ),
                        _c("label", { staticClass: "kr-amount-label" }, [
                          _vm._v(_vm._s(_vm.amountLabel)),
                        ]),
                      ])
                    : _c("label", { staticClass: "kr-amount-label" }, [
                        _vm._v(_vm._s(_vm.amountLabel)),
                      ]),
                ],
                1
              )
            : _c("div", {
                staticClass: "kr-method-info",
                class: { "kr-hidden": _vm.spinnerVisible && _vm.isLoading },
              }),
          _vm.spinnerVisible && _vm.isLoading
            ? _c("KryptonLoadingAnimation")
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }