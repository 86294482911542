<template lang="pug">
  section.kr-sf-cards(:class="dynamicClasses")
    label.kr-sf-cards__label(v-show="hasLabel") {{ label | capitalize | colon  }}
    template(v-if="hasCardFormExpanded && !onlyButton")
      slot
    SmartFormGridCardsButton(
      v-else
      v-on:selected="loadMethod"
      :locked="allLocked"
    )
    SmartFormGridCardsError(v-if="hasErrorComponent")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormGridCardsButton from '@/host/components/smartform/grid/cards/Button'
import SmartFormGridCardsError from '@/host/components/smartform/grid/cards/Error'

export default {
  name: 'SmartFormGridCards',
  components: {
    SmartFormGridCardsButton,
    SmartFormGridCardsError
  },
  props: {
    onlyButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'outsideCardsForm',
      'cardsFormExpanded',
      'isSmartFormPopin',
      'isWalletSmartForm',
      'hasTokens',
      'hasAnyActiveMethod',
      'hasSplitPayments'
    ]),
    ...mapState({
      registerMode: state => state.amount === 0,
      paymentDone: state => state.smartForm.paymentDone
    }),
    dynamicClasses() {
      return {
        'kr-sf-cards--register': this.registerMode && !this.walletMode
      }
    },
    hasErrorComponent() {
      return (
        !this.isSmartFormPopin &&
        this.walletMode &&
        !this.hasCardFormExpanded &&
        !this.onlyButton
      )
    },
    hasCardFormExpanded() {
      return this.cardsFormExpanded && !this.outsideCardsForm
    },
    walletMode() {
      return this.isWalletSmartForm && this.hasTokens
    },
    hasLabel() {
      return this.walletMode || this.registerMode || this.hasSplitPayments
    },
    /**
     * By default:
     *  Pay with another card
     *
     * If amount=0 and no card token:
     *   Register my card
     *
     * If amount=0 and any card token exist:
     *   Register another card
     */
    label() {
      if (this.registerMode) {
        if (this.walletMode)
          return this.translate('smartform_wallet_register_another_card')
        return this.translate('smartform_register_card')
      }
      return this.translate('smartform_wallet_newcard')
    },
    allLocked() {
      return this.paymentDone || this.hasAnyActiveMethod
    }
  },
  methods: {
    loadMethod(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
