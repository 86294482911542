<template lang="pug">  
  .kr-smart-form-embedded-header(:style="styles.wrapper" v-show="headerVisible")
    .kr-amount-wrapper
      span.kr-amount-label {{translate('amount')}}
      span.kr-amount-figure(:style="styles.amount.figure") {{amountLabel}}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SmartFormEmbeddedHeader',
  computed: {
    ...mapGetters(['translate']),
    ...mapState(['amountLabel']),
    ...mapState({
      headerConfig: state => state.form.smartform.embedded.header
    }),
    headerVisible() {
      return this.headerConfig.visibility
    },
    styles() {
      const styles = {}

      styles.wrapper = {}
      styles.wrapper.borderBottom = this.headerConfig.borderBottom

      styles.amount = {
        figure: {}
      }
      styles.amount.figure.color = this.headerConfig.amount.figure.color
      return styles
    }
  }
}
</script>
